import { ActionModel } from '../action/models/ActionModel';
import { ItemModel } from '../item.model';

interface ChangePayloadInterface {
  itemsBeforeChange?: ItemModel[];
  itemsAfterChange?: ItemModel[];
  globalAction?: ActionModel;
}

export class ChangeModel {
  public itemsBeforeChange?: ItemModel[] | null;

  public itemsAfterChange?: ItemModel[] | null;

  public globalAction?: ActionModel;

  constructor(payload: ChangePayloadInterface) {
    this.globalAction = payload.globalAction;
    this.itemsAfterChange = payload.itemsAfterChange ? payload.itemsAfterChange.map((el) => JSON.parse(JSON.stringify(el))) : null;
    this.itemsBeforeChange = payload.itemsBeforeChange ? payload.itemsBeforeChange.map((el) => JSON.parse(JSON.stringify(el))) : null;
  }

  get madeByAction() {
    return !!this.globalAction;
  }

  get madeByEdit() {
    return !this.globalAction;
  }

  set sequence(sequence: number) {
    if (this.madeByAction) {
      this.globalAction!.sequence = sequence;
    } else {
      this.itemsBeforeChange!.map((item) => (item.sequence = sequence));
      this.itemsAfterChange!.map((item) => (item.sequence = sequence));
    }
  }

  itemIsLocatedInChange(item: ItemModel) {
    return !!(this.madeByEdit && this.itemsBeforeChange!.map((obj) => obj.id).includes(item.id));
  }
}
