import { TaskPriorityEnum, TaskStatusEnum } from 'src/app/dashboard/tasks/tasks.enum';
import { TaskTriggerJobStatusEnum } from './../../../dashboard/tasks/tasks.enum';
import { BadgeTypeEnum, SetsConfig } from './badge.model';

export const BADGE_SETS: SetsConfig = {
  'active-status': {
    0: {
      isOutlined: true,
      translateKey: 'status.inactive',
      type: BadgeTypeEnum.danger,
    },
    1: {
      isOutlined: true,
      translateKey: 'status.active',
      type: BadgeTypeEnum.success,
    },
  },
  'task-job-status': {
    [TaskTriggerJobStatusEnum.DONE]: {
      isOutlined: true,
      translateKey: 'status.done',
      type: BadgeTypeEnum.success,
    },
    [TaskTriggerJobStatusEnum.ERROR]: {
      isOutlined: true,
      translateKey: 'status.error',
      type: BadgeTypeEnum.danger,
    },
    [TaskTriggerJobStatusEnum.PENDING]: {
      isOutlined: true,
      translateKey: 'status.pending',
      type: BadgeTypeEnum.info,
    },
    [TaskTriggerJobStatusEnum.WAITING]: {
      isOutlined: true,
      translateKey: 'status.waiting',
      type: BadgeTypeEnum.warning,
    },
  },
  'task-priority': {
    [TaskPriorityEnum.veryHigh]: {
      isOutlined: true,
      translateKey: 'tasks.priority.very_high',
      type: BadgeTypeEnum.veryHigh,
    },
    [TaskPriorityEnum.high]: {
      isOutlined: true,
      translateKey: 'tasks.priority.high',
      type: BadgeTypeEnum.high,
    },
    [TaskPriorityEnum.medium]: {
      isOutlined: true,
      translateKey: 'tasks.priority.medium',
      type: BadgeTypeEnum.medium,
    },
    [TaskPriorityEnum.low]: {
      isOutlined: true,
      translateKey: 'tasks.priority.low',
      type: BadgeTypeEnum.low,
    },
    [TaskPriorityEnum.veryLow]: {
      isOutlined: true,
      translateKey: 'tasks.priority.very_low',
      type: BadgeTypeEnum.veryLow,
    },
  },
  'task-status': {
    [TaskStatusEnum.canceled]: {
      isOutlined: false,
      translateKey: 'tasks.status.canceled',
      type: BadgeTypeEnum.dark,
    },
    [TaskStatusEnum.finished]: {
      isOutlined: false,
      translateKey: 'tasks.status.finished',
      type: BadgeTypeEnum.success,
    },
    [TaskStatusEnum.inProgress]: {
      isOutlined: false,
      translateKey: 'tasks.status.in_progress',
      type: BadgeTypeEnum.info,
    },
    [TaskStatusEnum.ignored]: {
      isOutlined: false,
      translateKey: 'tasks.status.ignored',
      type: BadgeTypeEnum.danger,
    },
    [TaskStatusEnum.new]: {
      isOutlined: false,
      translateKey: 'tasks.status.new',
      type: BadgeTypeEnum.warning,
    },
  },
  'seo-checks': {
    false: {
      isOutlined: false,
      translateKey: 'projects.site.page.issues.bad',
      type: BadgeTypeEnum.danger,
    },
    true: {
      isOutlined: false,
      translateKey: 'projects.site.page.issues.good',
      type: BadgeTypeEnum.success,
    },
  },
};
