<div [formGroup]="form" class="form-fields-in-text">
  <div *ngFor="let item of items" class="item">
    <ng-container *ngTemplateOutlet="item.isText ? textTemp : formFieldTemp; context: item"></ng-container>

    <ng-template #textTemp let-data="data">
      {{ data }}
    </ng-template>

    <ng-template #formFieldTemp let-data="data" let-key="key">
      <span class="param-field">
        <ng-container [ngSwitch]="data.field">
          <mat-form-field *ngSwitchCase="paramFieldEnum.input" class="no-error">
            <input
              [attr.step]="getStep(data.type)"
              [formControlName]="key"
              [placeholder]="getPlaceholder(data.type)"
              [type]="$any(inputTypeAttribute)[data.type] || 'text'"
              matInput
            />
            <app-form-field-error-display matError></app-form-field-error-display>
          </mat-form-field>

          <mat-form-field *ngSwitchCase="paramFieldEnum.select" class="no-error">
            <mat-select [formControlName]="key">
              <mat-option *ngFor="let option of data.data" [value]="option.value">{{ option.name }}</mat-option>
            </mat-select>
            <app-form-field-error-display matError></app-form-field-error-display>
          </mat-form-field>

          <mat-form-field *ngSwitchCase="paramFieldEnum.multiselect" class="no-error">
            <mat-select [compareWith]="compareWithFn" [formControlName]="key" multiple>
              <mat-option *ngFor="let option of data.data" [value]="option.value">{{ option.name }}</mat-option>
            </mat-select>
            <app-form-field-error-display matError></app-form-field-error-display>
          </mat-form-field>
        </ng-container>
      </span>
    </ng-template>
  </div>
</div>
