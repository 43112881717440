import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { map, takeUntil } from 'rxjs/operators';
import { ProjectModel } from 'src/app/dashboard/project/project.model';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { filterList } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-projects-list-select',
  templateUrl: './projects-list-select.component.html',
})
export class ProjectsListSelectComponent implements OnInit, OnDestroy {
  @Input() formCtrl!: UntypedFormControl;
  @Input() isDefaultyActiveProject: boolean = false;
  @Input() isDisabled = false;
  @Input() isEditableLabel: boolean = false;

  @Output() valueChange: EventEmitter<void> = new EventEmitter();

  currentProjectName!: string;
  filteredProjects!: ProjectModel[];
  noProjectSign = '- -';
  projectFilterCtrl: UntypedFormControl = new UntypedFormControl();

  private readonly projectService = inject(ProjectService);
  private onDestroy$ = new Subject<void>();
  private projects!: ProjectModel[];

  ngOnInit(): void {
    this.currentProjectName = this.noProjectSign;

    this.projectService
      .getProjects(999, 0)
      .pipe(map((res) => res.data))
      .subscribe((res) => {
        this.projects = res;
        this.filteredProjects = res;

        this.currentProjectName = res.find((project) => project.id === this.formCtrl.value)?.name || this.noProjectSign;
      });

    if (this.isDisabled) {
      this.formCtrl.disable({ emitEvent: false });
    } else {
      this.projectFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.filterProjects());
    }

    if (this.isDefaultyActiveProject && !this.formCtrl.value) {
      const currentProjectId = this.projectService.activeProject$.getValue()?.id || null;
      currentProjectId && this.formCtrl.setValue(currentProjectId);
    }
  }

  private filterProjects() {
    this.filteredProjects = filterList(this.projects, this.projectFilterCtrl.value);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
