// TODO: basic extends with: { id: number; name: string; }

import { BrandEnum } from 'src/app/shared/enums/brand.enum';

export interface AdwordsCustomer {
  id: string;
  name: string;
  manager: boolean;
  accounts?: AdwordsCustomer[];
}

export interface AnalyticsAccount {
  id: string;
  name: string;
}

export interface AnalyticsProperty {
  id: string;
  name: string;
}

export interface AnalyticsProfile {
  id: string;
  name: string;
}
export interface BingCustomer {
  id: number;
  name: string;
}
export interface BingAccount {
  id: number;
  name: string;
  number: string;
}

export interface FacebookAccount {
  id: string;
  name: string;
}

export interface FacebookBusiness {
  id: string;
  name: string;
}

export interface FacebookPage {
  id: string;
  name: string;
}

export interface FacebookPost {
  created_time: string;
  id: string;
  message: string;
}

export interface ConnectionUrlInterface {
  active: string;
  brand?: BrandEnum;
  type: string;
  url: string;
}

export interface MerchantCenterAccount {
  account_id: string;
  name: string;
  sub_accounts?: boolean;
}

export enum ServicesSlugsEnum {
  ads = 'GOOGLE_ADS', // @TODO: Które z tych powinno być obsługiwane?
  adwords = 'GOOGLE_ADWORDS', // @TODO: Które z tych powinno być obsługiwane?
  analytics = 'GOOGLE_ANALYTICS',
  analytics4 = 'GOOGLE_ANALYTICS_4',
  gsc = 'GOOGLE_SEARCH_CONSOLE',
  bing = 'MICROSOFT_ADVERTISING',
  facebook = 'FACEBOOK_FACEBOOK',
  facebookBusiness = 'FACEBOOK_BUSINESS',
  gmc = 'GOOGLE_MERCHANT_CENTER',
  shopify = 'SHOPIFY_ADMIN',
}
