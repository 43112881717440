import { EnvironmentInterface, EnvironmentTypesEnum } from './env.model';

export const environment: EnvironmentInterface = {
  // eslint-disable-next-line global-require
  appVersion: require('../../package.json').version,
  apiUrl: 'https://api-beta.sembot.ovh/api/v1',
  dataUrl: 'https://api-beta.sembot.ovh',
  stripePublicKey: 'pk_test_51J56QlJvqs0qeOPOK20bOMhWnrTYxNHz7p32CBBDUehDAb1tYMKt6TkC1PcgCNhKq4cfUiGNvBYyTKH8UJsS0fwT00ITIzr85Y',
  domain: 'sembot.com',
  production: false,
  trustedUsers: [1, 3, 6, 2, 5],
  type: EnvironmentTypesEnum.beta,
  pluginMode: null,
  shopifyPublic: 'd2dd01062a7c9d0dd9a80e0823ed0e28',
  shopifyName: 'sembot-beta',
  recaptcha: {
    siteKey: '6LeJo44mAAAAAIHVxGTeSFueDVOp__PY1s1sRrFs', // localhost, beta, dev
  },
};
